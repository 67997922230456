/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const cronogramaSection = css`
  background-color: ${colors.white};
  padding: 60px 0 200px;
  font-family: "Titillium Web", sans-serif;

  &:before {
    content: " ";
    height: 2px;
    width:80%;
    max-width: 800px;
    background-color: #d0d0d0;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  h2 {
    font-size: 64px;
    margin-bottom: 12px;
    line-height: 66px;
    font-weight: 700;
  }

  img {
    margin-right: 8px;
  }

  ul {
    li {
      padding: 0 20px;
      display: inline-block;
      img {
        margin: 0;
      }
    }
  }

  p {
    color: #525252;
  }

  .cbl{
    position:absolute ;
    bottom:0 ;
    left:0 ;
  }
  @media (min-width: 320px) and (max-width: 767px) {
      .cbl{
        width: 40%;;
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      h2{
        margin-bottom: 32px;
      }
    }
`;


export const linksGabaritos = css`
  margin: 60px 0 0;
  a {
    display: block;
    padding: 4px 0;
    font-size: 26px;
    color: #6b6b6b;
    font-weight: 600;
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 18px;
      padding: 10px 0;
    }
  }
  
`;

export const dataItem = css`
  display: flex;
  position: relative;
  padding: 10px 15px;
  width: 100%;

  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction: column;
    padding: 16px 30px 20px;
  }

  &:nth-child(2){   
    > div:first-child{
        background-color: rgb(84 92 255 / 50%);
    }
    span::before{
          background-color: ${colors.primaryVar3};
        }
    
  }
  

  &:nth-child(3){   
    > div:first-child{
        background-color: rgb(84 92 255 / 50%);
    }
    span::before{
          background-color: ${colors.primaryVar3};
        }
    
  }

  &:nth-child(4){   
    > div:first-child{
        background-color: rgb(84 92 255 / 75%);
    }
    span::before{
          background-color:  ${colors.primaryVar3};
        }
    div{
        color: ${colors.primaryVar3};
    }
  }

  &:nth-child(5){   
    div:nth-child(1){
        background-color: rgb(84 92 255 / 90%);
        justify-content: center;
        color:#ffffff;
        span::before{
          background-color: #ffffff;
        }
        div{
          background-color: transparent;
        }
    }
  }
  &:nth-child(6){   
    div:nth-child(1){
        background-color: rgb(84 92 255);
        justify-content: center;
        color:#ffffff;
        span::before{
          background-color: #ffffff;
        }
    }
  }

  &:after{
    content: '';
    width: 90%;
    height: 2px;
    background-color: #ddd;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const periodo = css`
  background-color: rgb(84 92 255 / 25%);
  display: flex;
  border-radius: 10px;
  font-size: 72px;
  font-weight: 700;
  padding: 8px;
  color: ${colors.primary};
  letter-spacing: 0;
  height: 110px;
  width: 274px;
  justify-content: center;

  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }

  div {
    position: relative;
    padding-right: 20px;
    line-height: 60px;
    margin-top: 13px;

    span {
      position: absolute;
      font-size: 18px;
      display: inline-block;
      padding: 0;
      line-height: 18px;
      transform: rotate(270deg);
      right: -16px;
      top: 25px;
      width: 48px;
      text-align: center;
      display: flex;
      justify-content: center;

      &::before{
        content: '';
        width: 100%;
        height: 2px;
        background-color: ${colors.primaryVar3};
        display: block;
        position: absolute;
        top: -2px;
      }
    }

    &:nth-child(2) {
      padding: 0 15px;
      font-size: 48px;
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
    }
  }
`;

export const periodoInfo = css`
  padding: 10px 0 10px 30px;
  height: auto;

  h3 {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: #b1b1b1;
    margin: 0;
  }

  p {
    font-size: 24px;
    line-height: 31.7px;
    font-weight: 400;
    color: #b1b1b1;
    margin: 0;
    max-width: 810px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding:10px 0;
    height: auto;

    h3{
      font-size: 28px;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding: 0px 0 0px 30px;
    height: 120px;
    h3 {
    font-size: 32px;
  }
  }
`;

export const emBreve = css`
  background-color: rgba(177, 177, 177, 0.25);
  border-radius: 10px;
  padding: 8px 16px 0;
  text-align: center;
  margin: 36px auto 0;
  h2, p{
    color: #b1b1b1;
  }
  p{
    font-size: 1.8em;
  }
`
