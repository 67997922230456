/** @jsx jsx */
import React from "react";
import { AboutTwoData, Corners } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'

import { 
  abAgency,
  pr79,
  molecula
}  from '../assets/styles/AboutTwo.styles'
import {
  secTitle,
  secDesk,
  commonSection,
} from "../assets/styles/layout.styles";

const { primaryCBR } = Corners;

const AboutTwo = () => {
  const { sectionContent, button, gallery } = AboutTwoData;
  return (
    <section css={[commonSection,abAgency]}>
      <Container>
        <Row>
        <Col lg={6} md={12} sm={12}>
            <div  css={molecula}>
              <img src={sectionContent.img2} />
              <img 
                data-aos={"zoom-in"} 
                data-aos-duration={"1000"}  
                data-aos-delay={"500"} 
                data-aos-once={true} 
                src={sectionContent.img1} />
            </div>
          </Col>
          <Col css={pr79} lg={6} md={12} sm={12}>
            <img src={sectionContent.img3} />
            <h2 css={secTitle}>{sectionContent.title}<span>{sectionContent.highlight}</span></h2>
            <p css={secDesk}>{sectionContent.text}</p>
            <a href={button.url}>
              <span>{button.label}</span>
            </a>
          </Col>
         
        </Row>
      </Container>
      <img src={primaryCBR} className="cbr" />
    </section>
  );
};

export default AboutTwo;
