/** @jsx jsx */
import React, { useState } from "react";
import { Link } from "gatsby";
import { stepsImage, Corners, comoParticiparData } from "@/data";
import { jsx } from "@emotion/react";

import { comoParticiparSection, steps, stepsMobile } from "../assets/styles/ComoParticipar.styles";
import {
  secTitle,
  subtitle,
  commonSection,
} from "../assets/styles/layout.styles";

const { primaryCTL } = Corners;

const ComoParticipar = () => {
  const [active, setActive] = useState(0);
  const { sectionContent, posts } = comoParticiparData;
  return (
    <section id="como-participar" css={[commonSection, comoParticiparSection]}>

      <img src={primaryCTL} className="ctl" />

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <h2 css={secTitle}>{sectionContent.title}</h2>
            <p css={subtitle}>{sectionContent.subTitle}</p>
          </div>

          <div css={stepsMobile} className="mobile">
            <div className="col-lg-12 col-sm-12 col-md-12">
            <ul>
                  {posts.map(({ id, title, text, image }, index) => (
                    <li>
                        <img 
                          data-aos={"zoom-in"} 
                          data-aos-once={true} 
                          data-aos-duration={"900"} 
                          src={image} alt="como participar"
                          />
                        
                        <h3>
                          <span>{id}</span>
                          {title}
                        </h3>
                        <p>{text}</p>
                    </li>
                  ))}
                </ul>
            </div>
          </div>

          <div css={steps} className="col-lg-12 col-sm-12 col-md-12 desktop">
            <div className="row">
              <div className="col-lg-6 col-sm-12 col-md-6">
                <ul>
                  {posts.map(({ id, title, text }, index) => (
                    <li
                      key={index}
                      className={`${active === index ? "active" : " "}`}
                    >
                      <a
                        href="#"
                        onMouseOver={() => {
                          setActive(index);
                        }}
                        onClick={e => {
                          e.preventDefault();
                        }}
                      >
                        <div className="marker"></div>
                        <span>{id}</span>
                        <h3>{title}</h3>
                        <p>{text}</p>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6 col-sm-12 col-md-6 tab-content">

                {posts.map((post, index) => {
                  return index === active ? (
                    <div
                      className="tab-pane fade show active animated fadeIn"
                      id={`image_${index}`}
                      key={index}
                    >
                      <img src={post.image} />
                    </div>
                  ) : null;
                })}

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComoParticipar;
