/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";   
import  play  from "../images/video/play.svg" 
import play_press from "../images/video/playPress.svg"


export const whatWedo = css`
  position: relative;
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 72px;

  .video_shape{
    position: absolute;
    left: -43px;
    bottom: -57px;
    z-index: -1;
  }

`;

export const videoWrap = css`
  position: relative;
  margin-bottom: 100px;
  border-radius: 20px;
  overflow: hidden;
  border:4px solid ${colors.secondaryVar1};
  box-shadow: 10px 12px 0px 0px #050505;

  width: 100%;
  max-width: 800px;
  margin: 0 auto 45px;

  & img {
    width: 100%;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 50px;
    width:90% ;
    margin-bottom:10px;
  }
`;

export const playVideo = css`
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  & a {
    display: inline-block;
    width: 151px;
    height: 149px;
    border-radius: 0;
    position: relative;
    background-image: url(${play});
    color: #fff;
    font-size: 30px;
    line-height: 0.8;
    padding: 0;
    margin-bottom: 0;

    @media (min-width: 320px) and (max-width: 767px) {
      width: 81px;
    height: 80px;
    font-size: 15px;
    padding: 0;
    margin-bottom: 0;
    background-size: contain;
    }

    &:hover {
      background-image: url(${play_press});
      color: #fff;
    }
  }

  h2 {
    font-size: 50px;
    line-height: 1;
    letter-spacing: 2px;
    color: #000;
    text-transform: uppercase;
    margin: 0;

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 20px;
      letter-spacing: 1px;
    }
  }
`;

export const videoHeader = css`
  background-color:${colors.secondaryVar1};
  height: 70px;
  position: absolute;
  top: 0;
  width: 100%;

  span{
    color: #ffffff;
    font-size: 85px;
    letter-spacing: -2px;
    line-height: 20px;
    margin-left: 40px;
    font-family: auto;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    display:none ;
  }

`