/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const comoParticiparSection = css`
  .ctl {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ctr {
    position: absolute;
    top: 0;
    right: 0;
  }

  h2 {
    font-size: 48px;
    margin-bottom: 10px;
    text-align: left;
  }

  p {
    font-size: 18px;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 40px;
    padding-top: 80px;
    h2 {
      font-size: 32px;
      text-align: center;
      margin-bottom: 0;
    }
    p {
      width: 70%;
      margin: 0 auto;
      text-align: center;
      line-height: 18px;
    }
    .ctl {
      width: 40%;
    }
  }
`;

export const steps = css`
  font-family: "Titillium Web", sans-serif;

  img {
    width: 100%;
    margin-top: -70px;
  }

  ul {
    margin: 0;
    padding: 0;

    &:before {
      content: "";
      position: absolute;
      width: 16px;
      height: 90%;
      background-color: #eee;
      border-radius: 4px;
      left: -27px;
      top: -10px;
      z-index: 3;
    }

    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 100%;
      background-color: #000;
      border-radius: 4px;
      left: -20px;
      top: 0;
    }

    li {
      position: relative;
      transition: 0.1s;
      margin-bottom: 40px;
      opacity: 0.3;

      .marker {
        position: absolute;
        width: 16px;
        height: 100%;
        background-color: #545cff;
        left: -42px;
        top: 10px;
        z-index: 3;
      }

      &.active {
        opacity: 1;
      }

      &:hover {
        opacity: 1;
        a {
          text-decoration: none;
        }
      }

      &:nth-child(2) {
        h3 {
          font-size: 32px;
        }
      }
      &:nth-child(4) {
        h3 {
          font-size: 40px;
        }
      }
      span {
        position: absolute;
        background-color: ${colors.primaryVar2};
        display: inline-block;
        border-radius: 4px;
        color: #b4f8e2;
        top: 14px;
        left: 0;
        font-size: 32px;
        font-weight: 700;
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 6px 14px;
        line-height: 33px;
      }
      h3 {
        font-weight: 700;
        font-size: 48px;
        line-height: 66px;
        color: ${colors.primary};
        padding-left: 70px;
        letter-spacing: 0;
      }
      p {
        color: #495057;
        line-height: 21.6px;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    &.desktop {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    img {
      margin-top: 0px;
    }
    ul li {
        span{
            top: 0;
        }
      h3 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 16px;
      }
      &:nth-child(2) {
        h3 {
          font-size: 18px;
        }
      }
      &:nth-child(4) {
        h3 {
          font-size: 32px;
        }
      }
    }
  }
`;

export const stepsMobile = css`
  font-family: "Titillium Web", sans-serif;
  margin-top: 40px;

  ul {
    margin: 0;
    padding: 0;

    li {
      position: relative;
      margin-bottom: 40px;

      img {
        width: 100%;
      }

      &:nth-child(2) {
        h3 {
          font-size: 20px;
        }
      }
      &:nth-child(4) {
        h3 {
          font-size: 40px;
        }
      }

      h3 {
        font-weight: 700;
        font-size: 28px;
        line-height: 66px;
        color: ${colors.primary};
        padding-left: 70px;
        letter-spacing: 0;
        position: relative;

        span {
          position: absolute;
          background-color: ${colors.primaryVar2};
          display: inline-block;
          border-radius: 4px;
          color: #b4f8e2;
          top: 14px;
          left: 0;
          font-size: 32px;
          font-weight: 700;
          display: flex;
          align-content: center;
          justify-content: center;
          padding: 6px 14px;
          line-height: 33px;
        }
      }
      p {
        color: #495057;
        line-height: 21.6px;
        text-align: left;
        margin: 0;
        width: 100%;
      }
    }
  }
  @media (min-width: 767px) {
    &.mobile {
      display: none;
    }
  }
`;
