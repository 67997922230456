/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "../styles/layout.styles";

export const quemPodeSection = css`
  background-color: ${colors.primary};
  border-radius: 200px 0 200px 0;
  h2 {
    font-size: 56px;
    text-align: center;
    span {
      font-family: "Swanky and Moo Moo", cursive;
      color: ${colors.secondary};
      font-weight: 400;
      font-size: 76px;
    }
  }

  p {
    color: ${colors.white};
  }

  @media (min-width: 320px) and (max-width: 767px) {
    border-radius: 90px 0 90px 0;
    padding-top: 20px;
    h2 {
      font-size: 32px;
      line-height: 34px;
      span {
        font-size: 40px;
        display: block;
      }
    }
    p {
      font-size: 18px;
      width: 95%;
      margin: 0 auto;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  .swiper-container {
    padding-bottom: 30px;
    margin-top: 20px;
  }

  .swiper-pagination-bullet {
    background: ${colors.primaryVar1};
    opacity: 1;

    &.swiper-pagination-bullet-active {
      width: 20px;
      height: 8px;
      border-radius: 4px;
      background: ${colors.primaryVar2};
    }
  }
`;

export const profile_wrapper = css`
  position: relative;
  display: flex;
  justify-content: center;
  width: 270px;
  height: 330px;
  align-items: center;
  margin: 0 auto;
`;
export const profile = css`
  position: relative;
  text-align: center;
  width: 240px;
  height: 310px;
  border-radius: 20px;
  background-color: ${colors.secondary};
  border: 3px solid #1e1e1e;
  transition: 0.2s;

  h3 {
    font-size: 34px;
    font-weight: 700;
    position: relative;
    margin-bottom: 40px;
    font-family: "Titillium Web", sans-serif;
    color: #343a40;

    span {
      position: absolute;
      font-family: "Swanky and Moo Moo", cursive;
      color: #ffffff;
      font-weight: 400;
      right: 9px;
      transform: rotate(-7deg);
      top: 24px;
      letter-spacing: 0;

      &.livre {
        right: 30px;
      }
    }
  }

  p {
    color: #383838;
    line-height: 18px;
    font-family: "Titillium Web", sans-serif;
    font-size: 18px;
  }

  button {
    color: #6b6b6b;
    font-size: 18px;
    font-weight: 600;
    text-decoration: underline;
    font-family: "Titillium Web", sans-serif;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent !important;
      color: ${colors.primaryVar3} !important;
      box-shadow: none !important;
    }
    &:active,
    &:focus {
      background-color: transparent !important;
      color: #6b6b6b !important;
      box-shadow: none !important;
    }
  }

  &:hover {
    &.junior {
      background-color: #ffffff;
      margin: -10px 10px 0 -10px;
      border-color: ${colors.primaryVar3};

      div:nth-child(1) {
        background-color: ${colors.primaryVar3};
        span {
          color: ${colors.primaryVar2};
        }
      }
      div:nth-child(2) {
        h3 span {
          color: ${colors.secondaryVar1};
        }
      }
    }

    &.senior {
      background-color: #ffffff;
      margin: -10px 10px 0 -10px;
      border-color: ${colors.secondary};

      div:nth-child(1) {
        background-color: ${colors.secondary};
      }
      div:nth-child(2) {
        h3 span {
          color: ${colors.primary};
        }
      }
    }
  }
`;

export const profile_shadow = css`
  position: absolute;
  background-color: #1e1e1e;
  border-radius: 20px;
  width: 240px;
  height: 310px;
  z-index: -1;
`;

export const profile_header = css`
  width: 100%;
  background-color: #1e1e1e;
  height: 34px;
  position: relative;
  border-radius: 15px 15px 0 0;

  span {
    position: absolute;
    color: #6b6b6b;
    top: -5px;
    left: 12px;
    font-size: 75px;
    letter-spacing: -5px;
    line-height: 4px;
    font-family: auto;
  }
`;

export const profile_body = css`
  margin-top: 40px;
  padding: 0 10px;
  P {
    color: #383838;
  }
`;

export const sessionBt = css`
  font-size: 26px;
  padding: 10px 60px;
  border: 3px solid #383838;
  cursor: pointer;
  margin-top: 30px;
`;

export const modalProfile = css`
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 700px;
  min-height: 360px;
  border-radius: 20px;
  background-color: #ffffff;
  border: 3px solid ${colors.primaryVar3};
  margin: 0 auto;
  box-shadow: 12px 12px 0px #383838;

  &.senior {
    border: 3px solid ${colors.secondary};
    .bodyModal {
      h3 {
        color: ${colors.secondaryVar2};
        span{
          color: ${colors.primary};
        }
      }

    }
    .topModal {
      background-color: ${colors.secondary};
      span {
        color: ${colors.secondaryVar2};
      }
      button {
        &:after,
        &:before {
          background: ${colors.secondaryVar2};
        }
      }
    }
  }

  .topModal {
    width: 100%;
    background-color: ${colors.primaryVar3};
    height: 40px;
    position: relative;
    border-radius: 15px 15px 0 0;

    span {
      position: absolute;
      color: ${colors.primaryVar2};
      top: -5px;
      left: 12px;
      font-size: 75px;
      letter-spacing: -5px;
      line-height: 4px;
      font-family: auto;
    }

    button {
      background-color: transparent;
      font-weight: 600;
      height: 35px;
      width: 35px;
      position: absolute;
      right: 12px;
      top: 0px;
      border: none;

      :after {
        width: 20px;
        height: 2px;
        background: ${colors.primaryVar2};
        position: absolute;
        right: 6.5px;
        top: 17px;
        content: "";
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transition: all ease 400ms;
        -webkit-transition: all ease 400ms;
        -moz-transition: all ease 400ms;
      }

      &:before {
        width: 20px;
        height: 2px;
        background: ${colors.primaryVar2};
        position: absolute;
        right: 6.5px;
        top: 17px;
        content: "";
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transition: all ease 400ms;
        -webkit-transition: all ease 400ms;
        -moz-transition: all ease 400ms;
      }
    }
  }

  .bodyModal {
    padding: 20px;
    h3 {
      font-size: 40px;
      font-weight: 700;
      position: relative;
      margin-bottom: 40px;
      font-family: "Titillium Web", sans-serif;
      color: ${colors.primaryVar2};

      span {
        position: absolute;
        font-family: "Swanky and Moo Moo", cursive;
        color: ${colors.secondaryVar1};
        font-weight: 400;
        right: 38%;
        transform: rotate(-7deg);
        top: 29px;
        letter-spacing: 0;

        &.livre {
          right: 30px;
        }
      }
    }
    p {
      font-family: "Titillium Web", sans-serif;
      font-size: 18px;
      color: #495057;
      text-align: left;
      line-height: 22px;
    }
  }
`;
