/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const abAgency = css`
  padding-top: 20px;
  padding-bottom: 200px;
  font-family: "Titillium Web", sans-serif;

  h2 {
    margin-top: 20px;
    margin-bottom: 36px;
    span {
      font-family: "Swanky and Moo Moo", cursive;
      color: ${colors.primary};
      font-weight: 400;
      font-size: 56px;
      display: block;
    }
  }

  p {
    font-size: 18px;
    line-height: 21.6px;
    color: #525252;
    margin-bottom: 16px;
  }

  a {
    font-size: 24px;
    font-weight: 600;
    color: #6b6b6b;
    text-decoration: underline;
  }

  & .ab_img2 {
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .cbr {
    position: absolute;
    bottom: -1px;
    right: 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 60px;

    .cbr {
      width: 40%;
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      span {
        font-size: 38px;
      }
    }
  }
`;

export const pr79 = css`
  padding-right: 79px;

  img {
    position: absolute;
    right: 100px;
    top: -100px;
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 120px;
    padding-right: 15px;
    img {
      right: 32px;
      top: 50px;
      width: 70px;
    }
    p {
      text-align: justify;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-right: 15px;
    padding-top: 170px;
    img {
      right: 10px;
      top: 80px;
    }
  }
`;

export const mb45 = css`
  margin-bottom: 45px;
  @media (min-width: 768px) and (max-width: 990px) {
    margin-bottom: 30px;
  }
`;

export const abImg = css`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  &:nth-child(2) {
    position: relative;
    top: 30px;
    margin-top: 0;
    &:after {
      @media (min-width: 768px) and (max-width: 990px) {
        width: 350px;
        left: 15px;
      }
    }
  }

  & img {
    max-width: 100%;
    height: auto;
    -webkit-box-shadow: -2px 4px 30px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -2px 4px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: -2px 4px 30px 0px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 320px) and (max-width: 767px) {
    position: relative;
    margin: 50px 0 0;
    &:after {
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    position: relative;
    float: right;
    right: -5px;
  }
`;

export const molecula = css`
  padding: 15px;
  position: relative;
  img {
    max-width: 100%;
    &:nth-child(2) {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    img{
      width: 100%;
    }
  }
`;
