/** @jsx jsx */
import React, {useState} from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ClientCarouselData } from "@/data";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { jsx } from '@emotion/react'


import { 
  quemPodeSection, 
  profile,
  profile_header,
  profile_body,
  profile_wrapper,
  profile_shadow,
  sessionBt,
  modalProfile 
} from "../assets/styles/QuemPode.styles";

 import {
  secTitle,
  subtitle,
  commonSection,
  commonBtn,
} from "../assets/styles/layout.styles";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination]);

const ModalProfile = () => {
  const { items } = ClientCarouselData;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    pagination: {
      el: "#client-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      0: {
        spaceBetween: 0,
        slidesPerView: 1,
      },
      576: {
        spaceBetween: 0,
        slidesPerView: 1,
      },
      768: {
        spaceBetween: 0,
        slidesPerView: 2,
      },
      992: {
        spaceBetween: 0,
        slidesPerView: 3,
      },
      1200: {
        spaceBetween: 0,
        slidesPerView: 4,
      },
    },
  };

  return (

      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <div css={modalProfile}>
            <div className="topModal">
              <span>...</span>
              <button onClick={handleClose}></button>
              </div>
            <div className="bodyModal">
            <h3>Júnior <span>Regular</span></h3>
              <p>
              <strong>Perfil: </strong>
              Perfil: Alunos do Ensino Fundamental têm a possibilidade de explorar as diferentes possibilidades de carreiras na área da saúde e compreender a importância que uma base sólida de habilidades e competências tem para o sucesso nessas carreiras.
              As provas abordam desde disciplinas clássicas que fundamentam as carreiras médicas até matérias contemporâneas consideradas essenciais para o sucesso profissional futuro (ex.: letramento tecnológico e econômico)
              </p>
            </div>
        </div>
      </Modal>
   
  );
};

export default ModalProfile;
