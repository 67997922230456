/** @jsx jsx */
import React, { Fragment, useState } from "react";
import { Link } from "gatsby";
import ModalVideo from "react-modal-video";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import "react-modal-video/css/modal-video.min.css";
import { SliderTwoData } from "@/data";
import { jsx } from '@emotion/react'

import { mainSliderTwo, videoTestimonial }  from '../assets/styles/SliderTwo.styles'
import { commonBtn}  from '../assets/styles/layout.styles'
import { videoWrap, playVideo, videoHeader }  from '../assets/styles/Video.styles'

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTwo = () => {
  const [isOpen, setOpen] = useState(false);
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    autoplay: {
      delay: 5000,
    },
  };
  return (
<Fragment>
<section css={mainSliderTwo}>
      <Swiper {...mainSlideOptions}>
        {SliderTwoData.map(({ image, name, title, arrow }, index) => (
          <SwiperSlide key={index}>
           
            <div css={videoWrap}>
                <div css={videoHeader}><span>...</span></div>
                <img src={image} alt="" />
                <div css={playVideo}>
                  <a
                    className="video_popup"
                    onClick={e => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                    href="#"
                  >
                  </a>
                </div>
            </div>

            <div css={videoTestimonial}>
              <img src={arrow} alt=""/>
              <h2>{title}</h2>
              <h3>{name}</h3>    
            </div>  
          </SwiperSlide>
        ))}
        <Link css={commonBtn} to="https://app.olimpiadademedicina.org/" className="quero_participar">Quero participar</Link>
        <div className="swiper-button-prev" id="main-slider-prev">
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <i className="fa fa-angle-right"></i>
        </div>
      </Swiper>
    </section>
    {(typeof(window) !== 'undefined') && <ModalVideo
        channel="vimeo"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        videoId="672843429"
        />
        }
</Fragment>
    
  );
};

export default SliderTwo;
