/** @jsx jsx */
import React from "react";
import { Corners } from "@/data"
import { jsx } from "@emotion/react";

import { 
    cronogramaSection, 
    periodoInfo,
    acompanhe
} from "../assets/styles/Final-2024.style";
import {
  secTitle,
  commonSection,
} from "../assets/styles/layout.styles";

const { secondaryCBL } = Corners;

const Cronograma = () => {
    
  return (
    <section id="cronograma" css={[commonSection,cronogramaSection]}>
        
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12 text-center">
            <h2 css={secTitle}>Final Vitalis <span>2024</span></h2>
          </div>
          <div css={periodoInfo} style={{ padding: 0, textAlign: 'center', width: '100%', marginTop: 24 }}>
            <h3 style={{ marginBottom: 32 }}>De 28 a 30 de setembro, em Brasília</h3>
            <p css={acompanhe}>Acompanhe nossas redes sociais!</p>
          </div>

        </div>
      </div>

      <img src={secondaryCBL} className="cbl" />
    </section>
  );
};

export default Cronograma;
