/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const faqSection = css`
  font-family: "Titillium Web", sans-serif;
  .cbr {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .ctr {
    position: absolute;
    top: 0;
    right: 0;
  }

  h2 {
    font-size: 48px;
    margin-bottom: 0;
    span {
      font-family: "Swanky and Moo Moo", cursive;
      color: ${colors.primary};
      font-weight: 400;
      font-size: 48px;
      padding-left: 10px;
    }
  }

  p {
    margin-bottom: 60px;
  }
  .accordion {
    border: none;
  }
  .accordion__item {
    border-top: 1px solid #dbdbdb;
  }
  .accordion__item:last-child {
    border-bottom: 1px solid #dbdbdb;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      font-size: 26px;
      span {
        font-size: 32px;
      }
    }
    p {
      font-size: 18px;
    }
    .cbr,
    .ctr {
      width: 40%;
    }
  }
`;

export const pergunta = css`
  font-size: 24px;
  position: relative;
  transition: 0.5s;

  div {
    padding: 30px 50px 30px 18px;
    color: #adb5bd;
    background-color: #ffffff;
    transition: 0.2s;
    &[aria-expanded="true"] {
      color: #545cff;
      font-weight: 700;
      outline: none;
     box-shadow: none;
    }
    &:before {
      position: absolute;
      right: 0px;
      top: 30px;
      color: #545cff;
    }
    &:hover {
      background-color: transparent;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 20px 50px 20px 18px;
      font-size: 16px;
    }
  }
`;

export const resposta = css`
  padding: 0px 18px 40px 18px;
  transition: 0.3s;
  p {
    font-size: 24px;
    line-height: 26px;
    color: #adb5bd;
    margin: 0;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0px 50px 20px 18px;
    p {
      font-size: 14px;
    }
  }
`;
