/** @jsx jsx */
import React from "react";
import { Link } from "gatsby";
import { Indicators, HandIcon } from "@/data"
import PDGabaritoJR from '../documents/2023/gabarito-categoria-JR.pdf'
import PDGabaritoSR from '../documents/2023/gabarito-categoria-SR.pdf'
import { jsx } from "@emotion/react";

import { 
    editionsSection, 
    indicator,
    linksGabaritos
} from "../assets/styles/Editions.Style";
import {
  secTitle,
  subtitle,
  white,
  commonSection,
  commonBtn,
  redBg
} from "../assets/styles/layout.styles";

const Editions = () => {
    const { iconHand } = HandIcon;
  return (
    <section id="edicoes" css={[commonSection,editionsSection]}>
        
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12 text-center">
            <h2 css={secTitle}>Edições Anteriores</h2>
            <p css={subtitle}>A cada ano, a carreira médica mais próxima de você.</p>
          </div>

          {Indicators.map(({ title, indNumber }, index) => (
              <div className="col-lg-3 col-md-6 text-center"
              data-aos={"fade-down"}
              data-aos-delay={(index+1)*150}
              data-aos-duration={900}
              data-aos-once={true}
              >
                <div css={indicator} className={`indicator-${index}`}>
                    <span>{indNumber}</span>
                    <p>{title}</p>
                </div>
            </div>
          ))}

        <div css={linksGabaritos} className="col-lg-12 col-sm-12 col-md-12 text-center">
            <a href={PDGabaritoJR}><img src={iconHand} /> Baixe o gabarito da prova categoria Júnior</a>
            <a href={PDGabaritoSR}><img src={iconHand} /> Baixe o gabarito da prova categoria Sênior</a>
            {/* <a href="#"><img src={iconHand} /> Baixe o gabarito da edição 2020</a>
            <a href="#"><img src={iconHand} /> Baixe o gabarito da edição 2019</a> */}
        </div>


        </div>
      </div>
    </section>
  );
};

export default Editions;
