/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const editionsSection = css`
  background-color: ${colors.white};
  padding: 60px 0;
  font-family: "Titillium Web", sans-serif;

  &:before{
    content: " ";
    height: 2px;
    width:80%;
    max-width: 800px;
    background-color: #d0d0d0;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }

  h2 {
    font-size: 64px;
    margin-bottom: 12px;
    line-height: 66px;
    font-weight: 700;
  }

  img {
    margin-right: 8px;
  }

  ul {
    li {
      padding: 0 20px;
      display: inline-block;
      img {
        margin: 0;
      }
    }
  }

  /* p {
    color: #525252;
  } */

  @media (min-width: 320px) and (max-width: 767px) {
    h2{
      font-size:32px;
    }
    p{
      font-size:18px;
      width:80% ;
      margin:0 auto 30px;
      line-height:24px ;
    }
  }
`;

export const indicator = css`
  width: 240px;
  height: 240px;
  padding: 0;
  box-shadow: 10px 12px 0px 0px #383838;
  border: 3px solid ${colors.primaryVar2};
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
  background: ${colors.primary};
  color: ${colors.white};
  text-align: center;
  display: flex;
  position: relative;
  outline: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;



  span {
    font-size: 72px;
    margin-bottom: 0;
    font-weight: 700;
    margin-bottom: 24px;
  }

  p {
    font-size: 32px;
    line-height: 30px;
    color: ${colors.gray};
    font-weight: 400;
    margin: 0%;
    @media (min-width: 320px) and (max-width: 767px) {
      font-size:20px ;
    }
  }

  &.indicator-1 {
    span {
      font-size: 64px;
    }
  }

  &.indicator-2,
  &.indicator-3 {
    p {
      font-size: 24px;
    }
  }

  
  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0px auto 40px;
    &.indicator-2,
    &.indicator-3 {
      p {
        font-size: 20px;
        line-height:20px;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 20px auto;
  }
`;

export const linksGabaritos = css`
  margin: 60px 0 0;
  a {
    display: block;
    padding: 4px 0;
    font-size: 26px;
    color: #6b6b6b;
    font-weight: 600;

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 18px;
      padding: 10px 0;
    }
  }
`;
