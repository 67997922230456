/** @jsx jsx */
import { css } from '@emotion/react'
import { colors } from "../styles/layout.styles"

export const downloadApp = css`
  padding:60px 0;
  border-radius: 0 200px 0 200px;
  font-family: 'Titillium Web', sans-serif;
  background-color: ${colors.secondary};

  h2 {
    margin-top:20px;
    margin-bottom: 16px;
    line-height: 60.5px;

    span{
        font-family: 'Swanky and Moo Moo',cursive;
        color: ${colors.primary};
        font-weight: 400;
        font-size: 72px;
        padding-left: 10px;
    }

  }

p{
    font-size: 26px;
    line-height: 34.35px;
    color:#383838;
    margin-bottom:16px;
  }

  a{
    font-size: 24px;
    font-weight: 600;
    color:#6b6b6b;
    text-decoration: underline;
  }

  & .ab_img2{
    left: 0;
    top: 100px;
    z-index: 2;

    &:after {
      position: absolute;
      top: -40px;
      left: 40px;
      content: "";
      border: 10px solid #e62b4a;
      width: 470px;
      height: 458px;
    }

    & img {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
      box-shadow: -2px 4px 35px 0px rgba(0, 0, 0, 0.3);
    }
  }
  .cbr{
    position: absolute;
    bottom:-1px;
    right: 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    border-radius:0 90px 0 90px;
  }
`

export const appText = css`

  ul{
    margin: 0;
    padding:0;
     li{
        display: inline-block;
        &:first-of-type{
            padding-right: 16px;
        }
     }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px;
    text-align: center;

    h2{
      width:80%;
      font-size:26px;
      line-height:28px;
      margin:0 auto 20px;
      span{
        font-size: 26px;
      }
    }
    p{
      font-size:18px;
      line-height:23px;
      width:80%;
      margin:0 auto 20px;
    }
    ul{
      li{
        &:first-of-type{
            padding-right: 0px;
            margin-bottom: 10px;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-right: 15px;
    text-align: center;
  }
`

export const appPhone = css`
padding:15px;
position: relative;
  img{
    max-width: 100%;
    position: absolute;
    left: 0;
    top: -160px;
    @media (min-width: 320px) and (max-width: 767px) {
      position:initial ;
    }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    img{
      position: relative;
      top: 0;
    }
  }
  
`
