/** @jsx jsx */
import React from "react";
import { PartnersImage,Corners } from "@/data";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { jsx } from "@emotion/react";

import { 
  partnersSection,
  partners,
  partnerImg 
} from "../assets/styles/Partners.style";
import {
  secTitle,
  commonSection,
} from "../assets/styles/layout.styles";

SwiperCore.use([Pagination]);

const Partners = () => {

  const { items } = PartnersImage;

  const { primaryCTL, primaryCTR } = Corners;

  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    pagination: {
      el: "#client-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      0: {
        spaceBetween: 0,
        slidesPerView: 2,
      },
      576: {
        spaceBetween: 20,
        slidesPerView: 2,
      },
      992: {
        spaceBetween: 20,
        slidesPerView: 3,
      },
      1200: {
        spaceBetween: 20,
        slidesPerView: 5,
      },
    },
  };

  return (
    <section css={[commonSection,partnersSection]}>

      <img src={primaryCTL} className="ctl" alt="corner"/>
      <img src={primaryCTR} className="ctr" alt="corner"/>

      {/* <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <h2 css={secTitle}>Nossos Parceiros</h2>

            <div css={partners}>
              <Swiper  {...carouselOptions}>
              {items.map(({ image }, index) => (
                <SwiperSlide key={index}>
                  <div css={partnerImg}>
                    <img src={image} alt="parceiro"/>
                  </div>
                </SwiperSlide>
              ))}
              <div
                className="swiper-pagination"
                id="client-carousel-pagination"
              ></div>
            </Swiper>




            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Partners;
