/** @jsx jsx */
import React from "react";
import { Link } from "gatsby";
import { FaqData, Corners } from "@/data";
import { jsx } from "@emotion/react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";

import { faqSection, pergunta, resposta } from "../assets/styles/Faq.style";
import {
  secTitle,
  subtitle,
  commonSection,
} from "../assets/styles/layout.styles";

const Faq = () => {
  const { secondaryCTR, secondaryCBR } = Corners;

  return (
    <section css={[commonSection, faqSection]}>
      <img src={secondaryCTR} className="ctr" />

      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <h2 css={secTitle}>
              Ficou com <span>alguma dúvida?</span>
            </h2>
            <p css={subtitle}>
              Se a sua dúvida não estiver aqui, fala com a gente!
            </p>
            {/* uuid="a" */}
            <Accordion preExpanded={["a"]}>
              {FaqData.map(({ question, response }, index) => (
                <AccordionItem>
                  <AccordionItemHeading css={pergunta}>
                    <AccordionItemButton>{question}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel css={resposta}>
                    <p>{response}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
      <img src={secondaryCBR} className="cbr" />
    </section>
  );
};

export default Faq;
