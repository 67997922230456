/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const partnersSection = css`
  .ctl {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ctr {
    position: absolute;
    top: 0;
    right: 0;
  }

  h2 {
    font-size: 24px;
    text-align: center;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .ctl,
    .ctr{
      width:40%;
      top:-1px;
    }

    padding-bottom:20px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom:60px;
  }
`;

// export const partners = css`
//   display: flex;
//   justify-content: center;

//   ul{
//     margin: 0;
//     padding:0;
//     li{
//       display: inline-block;
//       padding: 0 10px;
//     }
//   }
// `;

export const partners = css`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      padding: 0 10px;
    }
  }

  .swiper-slide {
    text-align: center;
  }

  .swiper-wrapper {
    justify-content: center;
  }

  .swiper-container {
    height: 80px;
  }

  .swiper-pagination {
    bottom: -10px;
  }

  .swiper-pagination-bullet {
    background: #efefef;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      width: 20px;
      height: 8px;
      border-radius: 4px;
      background: #adb5bd;
    }
  }
`;

export const partnerImg = css`
  text-align: center;

  img {
    max-width: 100%;
  }
`;
