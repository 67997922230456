/** @jsx jsx */
import React from "react";
import { appData, Corners } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'

import { 
  downloadApp,
  appText,
  mb45,
  abImg,
  appPhone
}  from '../assets/styles/App.styles'
import {
  secTitle,
  subtitle,
  secDesk,
  commonSection,
  commonBtn,
  redBg
} from "../assets/styles/layout.styles";

const { primaryCBR } = Corners;

const App = () => {
  const { sectionContent, button, gallery } = appData;
  return (
    <section css={[commonSection,downloadApp]}>
      <Container>
        <Row>
        <Col lg={6} md={12} sm={12}>
            {/* {gallery.map((item, index) => (
              <div css={abImg} className={`ab_img${index + 1}`} key={index}>
                <img src={item} alt="" />
              </div>
            ))} */}
            <div css={appPhone}>
              <img 
                  data-aos={"zoom-in-right"} 
                  data-aos-once={true} 
                  data-aos-easing="ease"
                  data-aos-duration={"900"} 
                  src={sectionContent.img1} 
                />
            </div>
          </Col>
          <Col css={appText} lg={6} md={12} sm={12}>
            <h2 css={secTitle}>{sectionContent.title}<span>{sectionContent.highlight}</span></h2>
            <p css={secDesk}>{sectionContent.text}</p>
           <ul>
            <li>
                <a href={button.apple}>
                    <img src={sectionContent.img2} />
                </a>
            </li>
            <li>
                <a href={button.google}>
                    <img src={sectionContent.img3} />
                </a>
            </li>
           </ul>
          </Col>
         
        </Row>
      </Container>
     
    </section>
  );
};

export default App;
