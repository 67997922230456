/** @jsx jsx */
import React, { useState } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ClientCarouselData } from "@/data";
import ModalProfile from "@/components/modal-profile";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { jsx } from "@emotion/react";

import {
  quemPodeSection,
  profile,
  profile_header,
  profile_body,
  profile_wrapper,
  profile_shadow,
  sessionBt,
  modalProfile,
} from "../assets/styles/QuemPode.styles";

import {
  secTitle,
  subtitle,
  commonSection,
  commonBtn,
} from "../assets/styles/layout.styles";

import "swiper/swiper-bundle.min.css";

SwiperCore.use([Pagination]);

const QuemPodeParticipar = () => {
  const { items } = ClientCarouselData;

  const [jrShow, setJrShow] = useState(false);
  const [jlShow, setJlShow] = useState(false);
  const [srShow, setSrShow] = useState(false);
  const [slShow, setSlShow] = useState(false);

  const jrClose = () => setJrShow(false);
  const jlClose = () => setJlShow(false);
  const srClose = () => setSrShow(false);
  const slClose = () => setSlShow(false);

  const jrHandleShow = () => setJrShow(true);
  const jlHandleShow = () => setJlShow(true);
  const srHandleShow = () => setSrShow(true);
  const slHandleShow = () => setSlShow(true);

  const carouselOptions = {
    spaceBetween: 0,
    loop: false,
    slidesPerView: 1,
    pagination: {
      el: "#client-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      0: {
        spaceBetween: 0,
        slidesPerView: 1,
      },
      576: {
        spaceBetween: 0,
        slidesPerView: 1,
      },
      768: {
        spaceBetween: 0,
        slidesPerView: 2,
      },
      992: {
        spaceBetween: 0,
        slidesPerView: 3,
      },
      1200: {
        spaceBetween: 0,
        slidesPerView: 4,
      },
    },
  };

  return (
    <section id="quem-pode" css={[commonSection, quemPodeSection]}>
      <Container>
        <Row>
          <div className="col-lg-12 col-sm-12 col-md-12 text-center">
            <h2 css={secTitle}>
              {" "}
              Quem pode <span>participar?</span>{" "}
            </h2>
            <p css={subtitle}>
              {" "}
              Veja qual dos perfis abaixo corresponde ao seu{" "}
            </p>
          </div>
        </Row>
        <Row>
          <Col lg={12}>
            <Swiper {...carouselOptions}>
              {items.map(({ url, title, type, desc, showProfile }, index) => (
                <SwiperSlide key={index}>
                  <div css={profile_wrapper}>
                    <div
                      css={profile}
                      className={title == "Júnior" ? "junior" : "senior"}
                    >
                      <div css={profile_header}>
                        <span>...</span>
                      </div>
                      <div css={profile_body}>
                        <h3>
                          {title}{" "}
                          <span
                            className={type == "Livre" ? "livre" : "regular"}
                          >
                            {type}
                          </span>
                        </h3>
                        <p>
                          <strong>Perfil:</strong> {desc}
                        </p>
                        {index === 0 ? (
                          <Button variant="primary" onClick={jrHandleShow}>
                            Saiba mais
                          </Button>
                        ) : (
                          ""
                        )}
                        {index === 1 ? (
                          <Button variant="primary" onClick={jlHandleShow}>
                            Saiba mais
                          </Button>
                        ) : (
                          ""
                        )}
                        {index === 2 ? (
                          <Button variant="primary" onClick={srHandleShow}>
                            Saiba mais
                          </Button>
                        ) : (
                          ""
                        )}
                        {index === 3 ? (
                          <Button variant="primary" onClick={slHandleShow}>
                            Saiba mais
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div css={profile_shadow}></div>
                  </div>
                </SwiperSlide>
              ))}
              <div
                className="swiper-pagination"
                id="client-carousel-pagination"
              ></div>
            </Swiper>
          </Col>
        </Row>
        <div className="col-lg-12 col-sm-12 col-md-12 text-center">
          <Link css={[commonBtn, sessionBt]} to="https://app.olimpiadademedicina.org/">Quero participar</Link>
        </div>
      </Container>

      <Modal
        show={jrShow}
        onHide={jrClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div css={modalProfile}>
          <div className="topModal">
            <span>...</span>
            <button onClick={jrClose}></button>
          </div>
          <div className="bodyModal">
            <h3>
              Júnior <span>Regular</span>
            </h3>
            <p>
              <strong>Perfil: </strong>
              Alunos do Ensino Fundamental têm a possibilidade de explorar as diferentes possibilidades de carreiras na área da saúde e compreender a importância que uma base sólida de habilidades e competências tem para o sucesso nessas carreiras.
            </p>
            <p>
            As provas abordam desde disciplinas clássicas que fundamentam as carreiras médicas até matérias contemporâneas consideradas essenciais para o sucesso profissional futuro (ex.: letramento tecnológico e econômico)
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        show={jlShow}
        onHide={jlClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div css={modalProfile}>
          <div className="topModal">
            <span>...</span>
            <button onClick={jlClose}></button>
          </div>
          <div className="bodyModal">
            <h3>
              Júnior <span>Livre</span>
            </h3>
            <p>
              <strong>Perfil: </strong>
              Perfil: Qualquer pessoa interessada na biologia do corpo humano e nas carreiras médicas que não esteja matriculada do 6º ao 9º ano do Ensino Fundamental pode aprofundar seus conhecimentos na área, fazendo as mesmas provas da Categoria Júnior Regular.
            </p>
            <p>
              <strong>Obs: </strong>Pais, avós e professores serão super bem-vindos para acompanhar seus filhos, netos e alunos nesse mergulho no mundo da Medicina e ciências afins!
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        show={srShow}
        onHide={srClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div css={modalProfile} className="senior">
          <div className="topModal">
            <span>...</span>
            <button onClick={srClose}></button>
          </div>
          <div className="bodyModal">
            <h3>
              Sênior <span>Regular</span>
            </h3>
            <p>
              <strong>Perfil: </strong>
              Na principal categoria da Vitalis, os participantes têm a oportunidade de aprofundar seus conhecimentos sobre as matérias que fundamentam a teoria, a pesquisa e a prática médicas, e de se aproximarem dos conteúdos que encontrarão futuramente na profissão, com questões vinculadas às bases curriculares das faculdades de medicina mais disputadas do país.
            </p>
            <p>
              Para manter o ritmo dos estudos para os vestibulares, os participantes também poderão avaliar seus conhecimentos sobre as matérias exigidas pelas principais faculdades do país.
            </p>
          </div>
        </div>
      </Modal>
      <Modal
        show={slShow}
        onHide={slClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div css={modalProfile} className="senior">
          <div className="topModal">
            <span>...</span>
            <button onClick={slClose}></button>
          </div>
          <div className="bodyModal">
            <h3>
              Sênior <span>Livre</span>
            </h3>
            <p>
              <strong>Perfil: </strong>
              Qualquer pessoa interessada em tópicos avançados sobre a biologia do corpo humano e as carreiras médicas, e que não esteja matriculada no Ensino Médio, pode aprofundar seus conhecimentos na área, fazendo as provas mais avançadas da Categoria Sênior Regular.
            </p>
            <p>
              Estudantes do Ensino Fundamental que queiram participar desta categoria são bem-vindos, lembrando que a prova estará bastante acima do seu nível escolar.
            </p>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default QuemPodeParticipar;
