/** @jsx jsx */
import React from "react";
import { HandIcon, Periodos, Periodos2, Corners } from "@/data"
import PDFRegulamento2024 from '../documents/2024/Edital -VITALIS 2024.pdf'
import PDFSyllabus2023 from '../documents/2023/Syllabus2023.pdf'
import { jsx } from "@emotion/react";

import { 
    cronogramaSection, 
    periodo,
    dataItem,
    periodoInfo,
    linksGabaritos,
    emBreve
} from "../assets/styles/Cronograma.style";
import {
  secTitle,
  commonSection,
} from "../assets/styles/layout.styles";

const { secondaryCBL } = Corners;

const Cronograma = () => {
    const { iconHand } = HandIcon;
    
  return (
    <section id="cronograma" css={[commonSection,cronogramaSection]}>
        
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12 text-center">
            <h2 css={secTitle}>Cronograma</h2>
          </div>
          <div css={periodoInfo} style={{ padding: 0, textAlign: 'center', width: '100%', marginTop: 24 }}>
            <h3 style={{ marginBottom: 32 }}>Segunda Fase</h3>
          </div>

          {Periodos.map(({ dataInicial, mesInicial,dataFinal, mesFinal,title, subTitle }, index) => (
            <div css={dataItem}>
                <div css={periodo}>
                    <div>{dataInicial}<span>{mesInicial}</span></div>
                    {index > 0 ? '' : <div>a</div>}
                    {index > 0 ? '' : <div>{dataFinal}<span>{mesFinal}</span></div>}
                </div>
                <div css={periodoInfo}>
                    <h3>{title}</h3>
                    <p>{subTitle}</p>
                </div>
            </div>
          ))}

        <div css={periodoInfo} style={{ padding: 0, textAlign: 'center', width: '100%', marginTop: 40 }}>
            <h3 style={{ marginBottom: 32 }}>Terceira Fase</h3>
          </div>

          {Periodos2.map(({ dataInicial, mesInicial,dataFinal, mesFinal,title, subTitle }, index) => (
            <div css={dataItem}>
                <div css={periodo}>
                    <div>{dataInicial}<span>{mesInicial}</span></div>
                    {index > 0 ? '' : <div>a</div>}
                    {index > 0 ? '' : <div>{dataFinal}<span>{mesFinal}</span></div>}
                </div>
                <div css={periodoInfo}>
                    <h3>{title}</h3>
                    <p>{subTitle}</p>
                </div>
            </div>
          ))}

        <div css={linksGabaritos} className="col-lg-12 col-sm-12 col-md-12 text-center">
            <a href={PDFRegulamento2024} target="_blank"><img src={iconHand} alt="Rgulamento"/> Baixe o Regulamento 2024</a>
            <a href={PDFSyllabus2023} target="_blank"><img src={iconHand} alt="Rgulamento"/> Baixe o Syllabus 2024</a>
        </div>

        {/* <div css={emBreve}>
          <h2>Em breve</h2>
          <p>Fique atento(a)!</p>
        </div> */}

        </div>
      </div>

      <img src={secondaryCBL} className="cbl" />
    </section>
  );
};

export default Cronograma;
