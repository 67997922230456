/** @jsx jsx */
import { css } from "@emotion/react";

export const mainSliderTwo = css`

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .quero_participar{
      display: table;
      margin:0 auto 60px;
      font-size: 26px;
      padding: 2px 40px;
      border: 2px solid #383838;
    }

  p,
  h3 {
    color: #000000;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .swiper-button-next, 
    .swiper-button-prev{
      display:none ;
    }
  }

  .swiper-container{max-width:1000px}

  .swiper-button-next i, 
  .swiper-button-prev i{
    display: none;
  }

  .swiper-button-next,
  .swiper-button-prev{
    top: 33%;
    &:after{
      color: #d0d0d0;
    }
  }
`;

export const videoTestimonial = css`
    width: 100%;
    max-width:800px;
    text-align: center;
    position: relative;
    margin: 0 auto;
    padding: 20px 0;

    h2{
      font-family: "Titillium Web", sans-serif;
      font-size: 40px;
      font-weight: 700;
      margin: 0;
    }

    h3{
      font-family: 'Swanky and Moo Moo', cursive;
      color:#525252;
      font-weight: 400;
      font-size: 48px;
    }

    img{
      position: absolute;
      left: 116px;
      top: -24px;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      img{
        display:none;
      }
      h2,h3{
        font-size:24px ;
      }
    }
`