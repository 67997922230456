/** @jsx jsx */
import React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import { SliderOneData } from "@/data";
import { jsx } from '@emotion/react'

import { 
  mainSlider,
  sliderBtn,
  shapeImg,
  infoSlider,
  imageSlider
}  from '../assets/styles/SliderOne.styles'
import { 
  commonBtn,
  secTitle,
  subtitle }  from '../assets/styles/layout.styles'

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderOne = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: false,
    // effect: "fade",
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    pagination: {
      el: "#carousel-pagination",
      type: "bullets",
      clickable: true,
    },
  };
  return (
    <section css={mainSlider}>
      <Swiper {...mainSlideOptions}>
        {SliderOneData.map(({ image, shape, subTitle, title, highlight, button }, index) => (
          <SwiperSlide key={index}>
            <div
              className="image-layer"
              // style={{ backgroundImage: `url(${image})` }}
            ></div>
            <Container>
              <Row>
                <Col lg={6}>
                 <div data-aos={"fade-right"} data-aos-delay={"300"} css={infoSlider}>
                    <h3 css={secTitle}>
                      <img css={shapeImg} src={shape} alt=""/>
                      {title}<span>{highlight}</span>
                      </h3>
                    <p css={subtitle}>{subTitle}</p>
                    <Link to={button.url} css={[commonBtn,sliderBtn]}>
                      <span>{button.label}</span>
                    </Link>
                 </div>
                </Col>
                <Col lg={6}>
                  <div data-aos={"fade"} data-aos-delay={"800"} css={imageSlider}>
                    <img src={image} alt="" />
                  </div>
                </Col>
              </Row>
            </Container>
          </SwiperSlide>
        ))}
        {/* <div className="swiper-button-prev" id="main-slider-prev">
          <i className="fa fa-angle-left"></i>
        </div>
        <div className="swiper-button-next" id="main-slider-next">
          <i className="fa fa-angle-right"></i>
        </div> */}
         <div
                className="swiper-pagination"
                id="carousel-pagination"
              ></div>
      </Swiper>
    </section>
  );
};

export default SliderOne;
